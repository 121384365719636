.Footer-container{
   gap: 2rem;
   padding: 3rem;
}
.footer{
    
   display: flex;
   justify-content: center;
   gap: 2rem;

  
}
.footer>img{
    width: 30px;
    height: 30px;

}
.footer>a>img{
    width: 30px;
    height: 30px;

}
.logo-f{
    display: flex;
    justify-content: center;
    
}
.logo-f>img{
    width: 10rem;
    margin-top: 50px;
    
}
