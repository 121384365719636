
.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header{
display: flex;
gap: 5rem;
font-weight: bold;
font-size: 3rem;
justify-content: center;
text-transform: uppercase; 
font-style: italic;


}
.programs-header>span:nth-of-type(2){
color: white;
}
.program-categories{
    display: flex;
    justify-content: space-between;
    gap: 1em;
}
.category:hover{
    background-color: var(--orange);
    cursor: pointer;
}
.category{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: grey;
    padding: 2rem;
    color: white;
    justify-content: space-between;
}
.category>:nth-child(1){
  width: 2rem;
  height: 2rem;
  fill: white;
}
.category>:nth-child(2){
    font-size: 1rem;
     font-weight: bold;
  }
  .category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
  }
  .join-now{
      display: flex;
      align-items: center;
      gap:2rem;
  }
  @media  screen and (max-width:768px) {

      .programs-header{
          flex-direction: column;
          gap: 1rem;
          font-size: x-large;
          align-items: center;
          justify-content: center;

      }
      .program-categories{
          flex-direction: column ;
      }
  }