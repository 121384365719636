.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
    
}
.left-j{
    
    flex: 1 1;
    
    font-size: 3rem;
    text-transform: uppercase;
    position: relative;
    font-weight: bold;
     color: aliceblue;
}

.right-j{
    display: flex;
    flex: 0.5;
}
.left-j>hr{
    position: absolute;
     width: 10.5rem;
     border: 2px solid var(--orange);
     border-radius:20% ;
   margin:-10px 0;
}

.button-42 {
  background-color: initial;
  background-image: linear-gradient(-180deg, #FF7E31, #E62C03);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
  height: 40px;
  line-height: 40px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  z-index: 9;
  border: 0;
  transition: box-shadow .2s;
}

.button-42:hover {
  box-shadow: rgba(253, 76, 0, 0.5) 0 3px 8px;
}
@media screen  and (max-width: 768px){
  .Join{
    flex-direction: column ;
    gap: 1rem;
  }
  .left-j{
    font-size: x-large;
    flex-direction: column;
  }
  .right-j{
    padding: 2rem;
  }
}